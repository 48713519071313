<template>
    <div class="main">
        <div class="banner">
            <div class="img_box">
                <img src="../../assets/image/marketing.png" alt="">
            </div>
            <div class="banner_info">
                <h3>营销网站</h3>
                <p>链接全球客户，让生意走向全球建站够快够省</p>
                <div class="banner_info_btn"><button>立即咨询</button></div>
            </div>
        </div>
        <div class="retailers">
          <div class="retailers_header">
            <h3>产品与服务</h3>
            <div class="retailers_header_nav">
              <ul>
                <li @click='change(1)' :class='currentIndex==1?"active":""'><span>企业官网</span></li>
                <li @click='change(2)' :class='currentIndex==2?"active":""'><span>营销网站</span></li>
                <li @click='change(3)' :class='currentIndex==3?"active":""'><span>门户网站</span></li>
                <li @click='change(4)' :class='currentIndex==4?"active":""'><span>外贸官网</span></li>
              </ul>
            </div>
          </div>
          <div class="retailers_con" v-show="currentIndex==1">
            <div class="retailers_con_left">
                <div>
                    <h3>企业官网</h3>
                    <p>互联网信息已经通达全球各个角落，企业通过互联网发布新的信息，以此来宣传企业的产品，宣传企业的服务，展示企业形象，并通过网络与各行各业进行交流和合作。</p>
                </div>
            </div>
            <div class="retailers_con_right">
                <img src="../../assets/image/marketing_retailers3.png" alt="">
            </div>
          </div>
          <div class="retailers_con" v-show="currentIndex==2">
            <div class="retailers_con_left">
                <div>
                    <h3>营销官网</h3>
                    <p>目前企业只有25%主要借助网络宣传,降低推广成本,快速传播产品信息与客户建造生意桥梁和传统渠道结合。营销型网站可以让您以营销的角度来分析网站</p>
                </div>
            </div>
            <div class="retailers_con_right">
                <img src="../../assets/image/marketing_retailers2.png" alt="">
            </div>
          </div>
          <div class="retailers_con" v-show="currentIndex==3">
            <div class="retailers_con_left">
                <div>
                    <h3>门户网站</h3>
                    <p>行业门户网站建设和一般企业网站相比，信息更新及时性要求高，访问量大；对改版的要求迫切，系统易用性、稳定性要求高；采、编、发流程可定制；宽带多媒体内容支持。</p>
                </div>
            </div>
            <div class="retailers_con_right">
                <img src="../../assets/image/marketing_retailers4.png" alt="">
            </div>
          </div>
          <div class="retailers_con" v-show="currentIndex==4">
            <div class="retailers_con_left">
                <div>
                    <h3>外贸官网</h3>
                    <p>整合了各种网络营销理念和网站运营管理方法，不仅注重网站建设的专业性，更加注重网站运营管理的整个过程，是企业网站建设与运营维护一体化的全程网络营销模式。</p>
                </div>
            </div>
            <div class="retailers_con_right">
                <img src="../../assets/image/marketing_retailers1.png" alt="">
            </div>
          </div>
        </div>
        <div class="case_wrap">
            <h3>案例展示</h3>
            <div class="case_con">
                <li class="case_con_list">
                    <div class="case_con_list_imgbox">
                        <img src="../../assets/image/marketing_case1.jpg" alt="">
                        <div class="imgbox_mask">
                            <span>众博工程机械挖掘机推土机PC模版</span>
                        </div>
                    </div>
                </li>
                <li class="case_con_list">
                    <div class="case_con_list_imgbox">
                        <img src="../../assets/image/marketing_case2.jpg" alt="">
                        <div class="imgbox_mask">
                            <span>众博蓝色铝塑型材五金件钢管PC模版</span>
                        </div>
                    </div>
                </li>
                <li class="case_con_list">
                    <div class="case_con_list_imgbox">
                        <img src="../../assets/image/marketing_case3.jpg" alt="">
                        <div class="imgbox_mask">
                            <span>众博包装印刷手提袋物购袋包装盒PC模版</span>
                        </div>
                    </div>
                </li>
                <li class="case_con_list">
                    <div class="case_con_list_imgbox">
                        <img src="../../assets/image/marketing_case4.jpg" alt="">
                        <div class="imgbox_mask">
                            <span>众博欧美婚纱婚纱礼服晚装礼服PC模板</span>
                        </div>
                    </div>
                </li>
                <li class="case_con_list">
                    <div class="case_con_list_imgbox">
                        <img src="../../assets/image/marketing_case5.jpg" alt="">
                        <div class="imgbox_mask">
                            <span>众博黑红大气机械机床机械配件PC模板</span>
                        </div>
                    </div>
                </li>
                <li class="case_con_list">
                    <div class="case_con_list_imgbox">
                        <img src="../../assets/image/marketing_case6.jpg" alt="">
                        <div class="imgbox_mask">
                            <span>众博手表腕表男表女表时尚珠宝配饰PC模板</span>
                        </div>
                    </div>
                </li>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  data(){
    return{
      currentIndex : 1
    }
    
  },
  methods:{
     change:function(index){
        this.currentIndex=index;
      }
  }
}
</script>
<style scoped>
    .banner{
        width: 100%;
        height: 540px;
        position: relative;
    }
    .banner img{
        width: 100%;
        height: 540px;
    }
    .banner_info h3{
        font-size: 60px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
    }
    .banner_info p{
        width: 360px;
        font-size: 24px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        margin: 10px 0 40px 0;
    }
    .banner_info_btn button{
        width: 185px;
        height: 55px;
        background: #FFFFFF;
        border-radius: 2px;
        border: 1px solid #979797;  
        color: #FF0610;
    }
    .banner_info{
        max-width: 1200px;
        width:1200px;
        position: absolute;
        left:50%;
        top:50%;
        transform: translate(-50%, -50%);
        text-align: left;
    }
    .retail_graphic{
        max-width: 1200px;
        margin: auto;
    }
    .retail_graphic h3{
        font-size: 36px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 600;
        color: #000000;
        padding: 50px 0 30px 0;
    }
    .retail_graphic img{
        width: 1100px;
    }
  .retailers_wrap{
    width: 100%;
    height: 600px;
    background: #F0F4FC;
    box-sizing: border-box;
    padding:20px 30px 30px 30px;
  }
  .retailers{
    max-width: 1200px;    
    margin:auto ;
    margin-top: 50px;
  }
  .retailers_header{
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #D6E4FF;
  }
  .retailers_header h3{
    font-size: 36px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #000000;
  }
  .retailers_header_nav ul{
    display: flex;
  }
  .retailers_header_nav ul li{
    margin: 0 50px;
    height: 80px;
    line-height: 80px;
    /* border-bottom: 3px solid red; */
    cursor: pointer;
  }
  .retailers_con{
    background: #fff;
    padding:30px 25px 30px 50px;
    box-sizing: border-box;
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
  }
  .retailers_con_right{
    width: 600px;
    height: 370px;
    /* border: 2px solid #D6E4FF; */
    margin-left: 48px;
  }
  .retailers_con_right img{
      width: 100%;
      height: 100%;
  }
  .retailers_con_left{
    flex: 1;
    text-align: left;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    box-sizing: border-box;
    padding: 40px 0 60px 0;
  }
  .retailers_con_left h3{
    font-size: 28px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #000000;
  }
  .retailers_con_left p{
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #4A4A4A;
    margin-bottom: 20px;
    margin:25px 0 50px 0;
  }
  .retailers_con_left_btn{
      width: 100%;
  }
  .retailers_con_left_btn button{
    width: 130px;
    height: 44px;
    border-radius: 2px; 
    outline: 0;
    border:0;
    cursor: pointer;
    background: #FF0610;
    /* border: 1px solid #9B9B9B; */
    color: #fff;
  }
  .case_wrap{
      max-width: 1200px;
      margin: auto;
      margin-bottom: 70px;
  }
  .case_wrap h3{
    font-size: 36px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #000000;
    margin: 70px 0 5px 0;
  }
  .case_con{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  .case_con_list{
    width: 32%;
    margin-right: 2%;
    /* border: 1px solid red; */
    height: 495px;
    position: relative;
    margin-top: 25px;
  }
  .case_con_list:nth-child(3n){
     margin-right: 0;
  }
  .case_con_list_imgbox{
    width: 100%;
    height: 495px;
  }
  .case_con_list_imgbox{
    height: 100%;
    width: 100%;
    overflow: hidden;
  }
  .case_con_list_imgbox img{
    width: 100%;
    /* height: 100%; */
  }

  .case_con_list .imgbox_mask{
    width: 100%;
    height: 76px;
    position: absolute;
    left: 0 ;
    bottom: 0;
    background: linear-gradient(180deg, rgba(129, 129, 129, 0) 0%, rgba(101, 101, 101, 0.85) 50%, #4A4A4A 100%);
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    text-align: left;
    box-sizing: border-box;
    padding-left:15px ;
    line-height: 76px;
    opacity: 0.7;
  }
  .active{
    border-bottom: 3px solid #FF0610;
    color: #FF0610;
  }
</style>